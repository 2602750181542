@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Lato", sans-serif;
}

h3 {
  font-size: 18px;
  font-weight: 500;
  color: black;
}

.info-box {
  @apply flex flex-wrap gap-4 bg-secondary-100 rounded-[5px] px-5 py-5 mt-6 w-full items-center justify-between;
}

.breadcrumb-text {
  font-size: 14px;
  font-weight: 300;
  color: black;
}

.breadcrumb-last-text {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.header-title-space {
  margin-top: 130px;
}

.MuiInputBase-root {
  background-color: white;
  height: 100%;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-firefox {
  scrollbar-color: #e4e6ef transparent;
  scrollbar-width: thin;
}
.td-firefox {
  background-clip: padding-box;
}
.table__cell {
  @apply whitespace-nowrap;
}
